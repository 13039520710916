.features22-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.features22-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.features22-container1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  max-width: 600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.features22-text11 {
  text-align: justify;
}
.features22-item-element
{
 padding-left: 20px;
}

.features22-container2 {
  width: 100%;
}
.features22-card1 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image1 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text13 {
  text-align: justify;
}
.features22-card2 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image2 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text15 {
  text-align: justify;
}
.features22-card3 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image3 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text17 {
  text-align: justify;
}
.features22-card4 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image4 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text19 {
  text-align: justify;
}
.features22-card5 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image5 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text21 {
  text-align: justify;
}
.features22-card6 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image6 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text23 {
  text-align: justify;
}
.features22-card7 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.features22-image7 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text25 {
  text-align: justify;
}
.features22-card8 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image8 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-card9 {
  color: var(--dl-color-theme-neutral-light);
  height: auto;
  min-height: 590px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.features22-image9 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.features22-text27 {
  text-align: justify;
}
.features22-text28 {
  display: inline-block;
}
.features22-text29 {
  display: inline-block;
}
.features22-text30 {
  display: inline-block;
}
.features22-text31 {
  display: inline-block;
}
.features22-text32 {
  display: inline-block;
}
.features22-text33 {
  display: inline-block;
}
.features22-text34 {
  display: inline-block;
}
.features22-text35 {
  display: inline-block;
}
.features22-text36 {
  display: inline-block;
}
.features22-text37 {
  display: inline-block;
}
.features22-text38 {
  display: inline-block;
}
.features22-text39 {
  display: inline-block;
}
.features22-text40 {
  display: inline-block;
}
.features22-text41 {
  display: inline-block;
}
.features22-text42 {
  display: inline-block;
}
.features22-text43 {
  display: inline-block;
}
.features22-text44 {
  display: inline-block;
}
.features22-text45 {
  display: inline-block;
}
@media(max-width: 991px) {
  .features22-max-width {
    flex-direction: column;
  }
  .features22-container1 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .features22-container1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .features22-card1 {
    width: 100%;
  }
  .features22-card2 {
    width: 100%;
  }
  .features22-card3 {
    width: 100%;
  }
  .features22-card4 {
    width: 100%;
  }
  .features22-card5 {
    width: 100%;
  }
  .features22-card6 {
    width: 100%;
  }
  .features22-card7 {
    width: 100%;
  }
  .features22-card8 {
    width: 100%;
  }
  .features22-card9 {
    width: 100%;
  }
}
