.gallery7-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery7-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gallery7-section-title {
  gap: 24px;
  width: auto;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery7-text1 {
  text-align: center;
}
.gallery7-text2 {
  text-align: center;
}
.gallery7-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery7-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery7-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery7-image1 {
  width: 100%;
  height: 650px;
  object-fit: cover;
}
.gallery7-image2 {
  width: 100%;
  height: 650px;
  object-fit: cover;
}
.gallery7-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery7-image3 {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.gallery7-image4 {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.gallery7-image5 {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.gallery7-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery7-image6 {
  width: 100%;
  height: 650px;
  object-fit: cover;
}
.gallery7-image7 {
  width: 100%;
  height: 650px;
  object-fit: cover;
}
.gallery7-text3 {
  display: inline-block;
}
.gallery7-text4 {
  display: inline-block;
}
@media(max-width: 991px) {
  .gallery7-content {
    align-items: center;
    flex-direction: column;
  }
  .gallery7-container2 {
    width: 100%;
  }
  .gallery7-image1 {
    flex: 0 0 auto;
    width: 100%;
  }
  .gallery7-container3 {
    width: 100%;
  }
  .gallery7-container4 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .gallery7-section-title {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}

/* Estilos para el cintillo de información */
.image-container {
  position: relative;
  overflow: hidden;
}

.image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.907);
  padding: 12px;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.image-container:hover .image-caption {
  transform: translateY(0);
}
.caption-title {


  color: #ed2a4f;
  margin-bottom: 20px;
}

.caption-details {

  color: #666;
  margin: 2px 0;
}